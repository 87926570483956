
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorFrontOrderLogNae, SelectorRecurringNae, SelectorStripeChargeNae, SelectorStripeDisputeNae, SelectorStripeEarlyFraudWarningNae, SelectorStripeRefundNae, SelectorStripeWebhookNae, SelectorWebhookNae } from '../models/ormSelectors';
import { useFrontOrderLogHookNae } from '../hooks/useFrontOrderLogHookNae';
import { useRecurringHookNae } from '../hooks/useRecurringHookNae';
import { useStripeChargeHookNae } from '../hooks/useStripeChargeHookNae';
import { useStripeDisputeHookNae } from '../hooks/useStripeDisputeHookNae';
import { useStripeEarlyFraudWarningHookNae } from '../hooks/useStripeEarlyFraudWarningHookNae';
import { useStripeRefundHookNae } from '../hooks/useStripeRefundHookNae';
import { useStripeWebhookHookNae } from '../hooks/useStripeWebhookHookNae';
import { useWebhookHookNae } from '../hooks/useWebhookHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'front-order-log') {
    return useFrontOrderLogHookNae;
  }
  if (schema === 'recurring') {
    return useRecurringHookNae;
  }
  if (schema === 'stripe-charge') {
    return useStripeChargeHookNae;
  }
  if (schema === 'stripe-dispute') {
    return useStripeDisputeHookNae;
  }
  if (schema === 'stripe-early-fraud-warning') {
    return useStripeEarlyFraudWarningHookNae;
  }
  if (schema === 'stripe-refund') {
    return useStripeRefundHookNae;
  }
  if (schema === 'stripe-webhook') {
    return useStripeWebhookHookNae;
  }
  if (schema === 'webhook') {
    return useWebhookHookNae;
  }
  return selector;
}

export function FrontOrderLogEmptyFieldNae(props: PropsLink) {
  const element = useFrontOrderLogHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function RecurringEmptyFieldNae(props: PropsLink) {
  const element = useRecurringHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function StripeChargeEmptyFieldNae(props: PropsLink) {
  const element = useStripeChargeHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function StripeDisputeEmptyFieldNae(props: PropsLink) {
  const element = useStripeDisputeHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function StripeEarlyFraudWarningEmptyFieldNae(props: PropsLink) {
  const element = useStripeEarlyFraudWarningHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function StripeRefundEmptyFieldNae(props: PropsLink) {
  const element = useStripeRefundHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function StripeWebhookEmptyFieldNae(props: PropsLink) {
  const element = useStripeWebhookHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function WebhookEmptyFieldNae(props: PropsLink) {
  const element = useWebhookHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

