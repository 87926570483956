import { MainButton } from '@newageerp/v3.bundles.buttons-bundle';
import { axiosInstance } from '@newageerp/v3.bundles.utils-bundle';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle'
import { toast } from '@newageerp/v3.templates.templates-core';
import { Wide, WideRow, FieldLabel } from '@newageerp/v3.bundles.form-bundle'
import React, { useState } from 'react'

export default function BalanceHistoryReportImport() {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);

  const uploadData = () => {
    if (!!uploadedFile) {
      setUploading(true);

      const fData = new FormData();
      fData.append("file", uploadedFile);

      axiosInstance
        .post("/app/plugins/BalanceHistory/doImport", fData, {
          headers: {
            Authorization: window.localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success(
            "Imported"
          );
          setUploading(false);
        }).catch(e => {
          toast.error("Error");
        });
    }
  }

  return (
    <WhiteCard isCompact={true}>
      <Wide>
        <WideRow
          label={<FieldLabel className={(!!uploadedFile ? "text-success" : "text-danger")}>File</FieldLabel>}
          control={<input type={"file"} onChange={(e: any) => setUploadedFile(e.target.files[0])} />}
        />

      </Wide>

      {!!uploadedFile && <MainButton onClick={uploadData} iconName='upload'>
        {uploading ? 'Uploading...' : 'Upload'}
      </MainButton>}

    </WhiteCard>
  )
}
