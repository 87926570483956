import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "FrontOrder",
        "schema": "front-order",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "FrontOrderLog",
        "schema": "front-order-log",
        "title": "Order log",
        "titlePlural": "Order logs",
        "required": [],
        "scopes": []
    },
    {
        "className": "Recurring",
        "schema": "recurring",
        "title": "Recurring",
        "titlePlural": "Recurrings",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder",
        "title": "Explore data folder",
        "titlePlural": "Explore data folder",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item",
        "title": "Explore data",
        "titlePlural": "Explore data",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeBalanceHistory",
        "schema": "stripe-balance-history",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeBalanceTransaction",
        "schema": "stripe-balance-transaction",
        "title": "Balance transaction",
        "titlePlural": "Balance transactions",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeCharge",
        "schema": "stripe-charge",
        "title": "Charge",
        "titlePlural": "Charges",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeChargeObject",
        "schema": "stripe-charge-object",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeCustomer",
        "schema": "stripe-customer",
        "title": "Customer",
        "titlePlural": "Customers",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeDispute",
        "schema": "stripe-dispute",
        "title": "Dispute",
        "titlePlural": "Disputes",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeEarlyFraudWarning",
        "schema": "stripe-early-fraud-warning",
        "title": "Early fraud warning",
        "titlePlural": "Early fraud warnings",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeInvoice",
        "schema": "stripe-invoice",
        "title": "Invoice",
        "titlePlural": "Invoices",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripePaymentIntent",
        "schema": "stripe-payment-intent",
        "title": "Payment intent",
        "titlePlural": "Payment intents",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeRefund",
        "schema": "stripe-refund",
        "title": "Refund",
        "titlePlural": "Refunds",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeSubscription",
        "schema": "stripe-subscription",
        "title": "Subscription",
        "titlePlural": "Subscriptions",
        "required": [],
        "scopes": []
    },
    {
        "className": "StripeWebhook",
        "schema": "stripe-webhook",
        "title": "Webhook",
        "titlePlural": "Webhooks",
        "required": [],
        "scopes": []
    },
    {
        "className": "SystemRun",
        "schema": "system-run",
        "title": "System run",
        "titlePlural": "System runs",
        "required": [],
        "scopes": []
    },
    {
        "className": "User",
        "schema": "user",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Webhook",
        "schema": "webhook",
        "title": "Webhook",
        "titlePlural": "Webhooks",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "StripeChargeObject": {
        "className": "StripeChargeObject",
        "schema": "stripe-charge-object"
    },
    "StripeCustomer": {
        "className": "StripeCustomer",
        "schema": "stripe-customer"
    },
    "StripeCharge": {
        "className": "StripeCharge",
        "schema": "stripe-charge"
    },
    "StripeInvoice": {
        "className": "StripeInvoice",
        "schema": "stripe-invoice"
    },
    "StripeRefund": {
        "className": "StripeRefund",
        "schema": "stripe-refund"
    },
    "StripeBalanceTransaction": {
        "className": "StripeBalanceTransaction",
        "schema": "stripe-balance-transaction"
    },
    "StripeEarlyFraudWarning": {
        "className": "StripeEarlyFraudWarning",
        "schema": "stripe-early-fraud-warning"
    },
    "StripeSubscription": {
        "className": "StripeSubscription",
        "schema": "stripe-subscription"
    },
    "StripePaymentIntent": {
        "className": "StripePaymentIntent",
        "schema": "stripe-payment-intent"
    },
    "StripeDispute": {
        "className": "StripeDispute",
        "schema": "stripe-dispute"
    },
    "SystemRun": {
        "className": "SystemRun",
        "schema": "system-run"
    },
    "FrontOrder": {
        "className": "FrontOrder",
        "schema": "front-order"
    },
    "StripeWebhook": {
        "className": "StripeWebhook",
        "schema": "stripe-webhook"
    },
    "Webhook": {
        "className": "Webhook",
        "schema": "webhook"
    },
    "FrontOrderLog": {
        "className": "FrontOrderLog",
        "schema": "front-order-log"
    },
    "Recurring": {
        "className": "Recurring",
        "schema": "recurring"
    },
    "SfKeyValueOrm": {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm"
    },
    "StripeBalanceHistory": {
        "className": "StripeBalanceHistory",
        "schema": "stripe-balance-history"
    },
    "User": {
        "className": "User",
        "schema": "user"
    },
    "SfExploreDataItem": {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item"
    },
    "SfExploreDataFolder": {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder"
    }
}