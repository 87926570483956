import { ORM } from "redux-orm";
import FrontOrderLogModel from "./FrontOrderLogModel"
import QueueModel from "./QueueModel"
import RecurringModel from "./RecurringModel"
import StripeChargeModel from "./StripeChargeModel"
import StripeDisputeModel from "./StripeDisputeModel"
import StripeEarlyFraudWarningModel from "./StripeEarlyFraudWarningModel"
import StripeRefundModel from "./StripeRefundModel"
import StripeWebhookModel from "./StripeWebhookModel"
import WebhookModel from "./WebhookModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    FrontOrderLogModel,
    QueueModel,
    RecurringModel,
    StripeChargeModel,
    StripeDisputeModel,
    StripeEarlyFraudWarningModel,
    StripeRefundModel,
    StripeWebhookModel,
    WebhookModel,
);

export default orm;