import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorFrontOrderLogNae = createSelector(orm.FrontOrderLogModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorRecurringNae = createSelector(orm.RecurringModel);
export const SelectorStripeChargeNae = createSelector(orm.StripeChargeModel);
export const SelectorStripeDisputeNae = createSelector(orm.StripeDisputeModel);
export const SelectorStripeEarlyFraudWarningNae = createSelector(orm.StripeEarlyFraudWarningModel);
export const SelectorStripeRefundNae = createSelector(orm.StripeRefundModel);
export const SelectorStripeWebhookNae = createSelector(orm.StripeWebhookModel);
export const SelectorWebhookNae = createSelector(orm.WebhookModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'FrontOrderLog') {
        return SelectorFrontOrderLogNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'Recurring') {
        return SelectorRecurringNae;
    }
        if (schema === 'StripeCharge') {
        return SelectorStripeChargeNae;
    }
        if (schema === 'StripeDispute') {
        return SelectorStripeDisputeNae;
    }
        if (schema === 'StripeEarlyFraudWarning') {
        return SelectorStripeEarlyFraudWarningNae;
    }
        if (schema === 'StripeRefund') {
        return SelectorStripeRefundNae;
    }
        if (schema === 'StripeWebhook') {
        return SelectorStripeWebhookNae;
    }
        if (schema === 'Webhook') {
        return SelectorWebhookNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'front-order-log') {
        return SelectorFrontOrderLogNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'recurring') {
        return SelectorRecurringNae;
    }
        if (schema === 'stripe-charge') {
        return SelectorStripeChargeNae;
    }
        if (schema === 'stripe-dispute') {
        return SelectorStripeDisputeNae;
    }
        if (schema === 'stripe-early-fraud-warning') {
        return SelectorStripeEarlyFraudWarningNae;
    }
        if (schema === 'stripe-refund') {
        return SelectorStripeRefundNae;
    }
        if (schema === 'stripe-webhook') {
        return SelectorStripeWebhookNae;
    }
        if (schema === 'webhook') {
        return SelectorWebhookNae;
    }
    }