import { useEffect } from "react";
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorStripeChargeNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IStripeChargeModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
  }

export const IStripeChargeFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges"
];

export function useStripeChargeHookNae(id: number) : IStripeChargeModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorStripeChargeNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: "stripe-charge",
        fields: IStripeChargeFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
