import Dashboard from "./Dashboard/Dashboard";
import DashboardEarlyFraudWarning from "./Dashboard/DashboardEarlyFraudWarning";
import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap";
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap";
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap";
import { CrmConfigSchema } from "./ConfigSchema/CrmConfigSchema";
import { StripeConfigSchema } from "./ConfigSchema/StripeConfigSchema";
import BalanceHistoryReportImport from "./BalanceHistory/Import/BalanceHistoryReportImport";

export const PluginsMap: any = {
    ...CustomEditComponentsMap,
    ...CustomViewComponentsMap,
    ...CustomListComponentsMap,

    "custom.routes": {
        '/c/dashboard/main': Dashboard,
        '/c/dashboard/early-fraud': DashboardEarlyFraudWarning,
        '/c/balance-history/import': BalanceHistoryReportImport,

    },
    config: {
        stripe: StripeConfigSchema,
        crm: CrmConfigSchema,
    }
}