import { INaeProperty } from "@newageerp/v3.app.main-bundle";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "front-order",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "front-order",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "email",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "front-order",
        "key": "orderData",
        "type": "array",
        "format": "string",
        "title": "orderData",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "front-order",
        "key": "referenceId",
        "type": "string",
        "format": "",
        "title": "referenceId",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "front-order-log",
        "key": "path",
        "type": "string",
        "format": "",
        "title": "path",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "referenceId",
        "type": "string",
        "format": "",
        "title": "referenceId",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "request",
        "type": "array",
        "format": "string",
        "title": "request",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "front-order-log",
        "key": "response",
        "type": "array",
        "format": "string",
        "title": "response",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "recurring",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "recurring",
        "key": "chargeId",
        "type": "string",
        "format": "",
        "title": "chargeId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "error",
        "type": "string",
        "format": "text",
        "title": "error",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "recurring",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "recurring",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "orderId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "piId",
        "type": "string",
        "format": "",
        "title": "piId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "piStatus",
        "type": "string",
        "format": "",
        "title": "piStatus",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "columns",
        "type": "string",
        "format": "text",
        "title": "columns",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "exploreId",
        "type": "string",
        "format": "",
        "title": "exploreId",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "folder",
        "type": "rel",
        "format": "sf-explore-data-folder",
        "title": "Folder",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlCount",
        "type": "string",
        "format": "text",
        "title": "sqlCount",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlData",
        "type": "string",
        "format": "text",
        "title": "sqlData",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "availableOnUtc",
        "type": "string",
        "format": "",
        "title": "availableOnUtc",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "createdUtc",
        "type": "string",
        "format": "",
        "title": "createdUtc",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "customerFacingAmount",
        "type": "number",
        "format": "float",
        "title": "customerFacingAmount",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-balance-history",
        "key": "customerFacingCurrency",
        "type": "string",
        "format": "",
        "title": "customerFacingCurrency",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "destinationPlatformCurrency",
        "type": "string",
        "format": "",
        "title": "destinationPlatformCurrency",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "destinationPlatformFee",
        "type": "number",
        "format": "float",
        "title": "destinationPlatformFee",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-balance-history",
        "key": "fee",
        "type": "number",
        "format": "float",
        "title": "fee",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-balance-history",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-balance-history",
        "key": "metaData",
        "type": "array",
        "format": "string",
        "title": "metaData",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "stripe-balance-history",
        "key": "net",
        "type": "number",
        "format": "float",
        "title": "net",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-balance-history",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "total",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-balance-history",
        "key": "transfer",
        "type": "string",
        "format": "",
        "title": "transfer",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "transferDateUtc",
        "type": "string",
        "format": "",
        "title": "transferDateUtc",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "transferGroup",
        "type": "string",
        "format": "",
        "title": "transferGroup",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-history",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "type",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceHistory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "fee",
        "type": "number",
        "format": "float",
        "title": "fee",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "net",
        "type": "number",
        "format": "float",
        "title": "net",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-balance-transaction",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "type",
        "additionalProperties": [],
        "description": "",
        "className": "StripeBalanceTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-charge",
        "key": "authCode",
        "type": "string",
        "format": "",
        "title": "authCode",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "billingDetails",
        "type": "array",
        "format": "string",
        "title": "billingDetails",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "stripe-charge",
        "key": "captured",
        "type": "boolean",
        "format": "",
        "title": "captured",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "stripe-charge",
        "key": "cardBrand",
        "type": "string",
        "format": "",
        "title": "cardBrand",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "cardLast4",
        "type": "string",
        "format": "",
        "title": "cardLast4",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "cardNetwork",
        "type": "string",
        "format": "",
        "title": "cardNetwork",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "disputed",
        "type": "boolean",
        "format": "",
        "title": "disputed",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "stripe-charge",
        "key": "disputes",
        "type": "array",
        "format": "stripe-dispute",
        "title": "disputes",
        "additionalProperties": [
            {
                "mapped": "stripeCharge"
            }
        ],
        "description": "",
        "className": "StripeCharge",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "stripe-charge",
        "key": "failureCode",
        "type": "string",
        "format": "",
        "title": "failureCode",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "failureMessage",
        "type": "string",
        "format": "",
        "title": "failureMessage",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-charge",
        "key": "metaData",
        "type": "array",
        "format": "string",
        "title": "metaData",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "stripe-charge",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "stripe-charge",
        "key": "paymentMethodType",
        "type": "string",
        "format": "",
        "title": "paymentMethodType",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "paymentType",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "receiptEmail",
        "type": "string",
        "format": "",
        "title": "receiptEmail",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "refunded",
        "type": "boolean",
        "format": "",
        "title": "refunded",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "stripe-charge",
        "key": "refunds",
        "type": "array",
        "format": "stripe-refund",
        "title": "refunds",
        "additionalProperties": [
            {
                "mapped": "stripeCharge"
            }
        ],
        "description": "",
        "className": "StripeCharge",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "stripe-charge",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "statementDescriptor",
        "type": "string",
        "format": "",
        "title": "statementDescriptor",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "statementDescriptorSuffix",
        "type": "string",
        "format": "",
        "title": "statementDescriptorSuffix",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "stripeBalanceTransaction",
        "type": "rel",
        "format": "stripe-balance-transaction",
        "title": "stripeBalanceTransaction",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-charge",
        "key": "stripeCustomer",
        "type": "rel",
        "format": "stripe-customer",
        "title": "stripeCustomer",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-charge",
        "key": "stripePaymentIntent",
        "type": "rel",
        "format": "stripe-payment-intent",
        "title": "stripePaymentIntent",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-charge",
        "key": "subForceCancel",
        "type": "boolean",
        "format": "",
        "title": "subForceCancel",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "stripe-charge",
        "key": "walletLast4",
        "type": "string",
        "format": "",
        "title": "walletLast4",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge",
        "key": "walletType",
        "type": "string",
        "format": "",
        "title": "walletType",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCharge",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-charge-object",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeChargeObject",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-customer",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCustomer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-customer",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "email",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCustomer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-customer",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCustomer",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-customer",
        "key": "metaData",
        "type": "array",
        "format": "string",
        "title": "metaData",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCustomer",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "stripe-customer",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeCustomer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-dispute",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-dispute",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "stripe-dispute",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-dispute",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-dispute",
        "key": "metaData",
        "type": "array",
        "format": "string",
        "title": "metaData",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "stripe-dispute",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "stripe-dispute",
        "key": "reason",
        "type": "string",
        "format": "",
        "title": "reason",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-dispute",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-dispute",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-dispute",
        "key": "stripeCharge",
        "type": "rel",
        "format": "stripe-charge",
        "title": "stripeCharge",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-dispute",
        "key": "stripePaymentIntent",
        "type": "rel",
        "format": "stripe-payment-intent",
        "title": "stripePaymentIntent",
        "additionalProperties": [],
        "description": "",
        "className": "StripeDispute",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "actionable",
        "type": "boolean",
        "format": "",
        "title": "actionable",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "fraudType",
        "type": "string",
        "format": "",
        "title": "fraudType",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "hasDisputes",
        "type": "boolean",
        "format": "",
        "title": "Has disputes",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "hasRefunds",
        "type": "boolean",
        "format": "",
        "title": "Has refunds",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "object",
        "type": "string",
        "format": "",
        "title": "object",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "processed",
        "type": "boolean",
        "format": "",
        "title": "processed",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "stripeCharge",
        "type": "rel",
        "format": "stripe-charge",
        "title": "stripeCharge",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-early-fraud-warning",
        "key": "stripePaymentIntent",
        "type": "rel",
        "format": "stripe-payment-intent",
        "title": "stripePaymentIntent",
        "additionalProperties": [],
        "description": "",
        "className": "StripeEarlyFraudWarning",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-invoice",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "StripeInvoice",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-invoice",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeInvoice",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-invoice",
        "key": "metaData",
        "type": "array",
        "format": "string",
        "title": "metaData",
        "additionalProperties": [],
        "description": "",
        "className": "StripeInvoice",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "stripe-invoice",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeInvoice",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-invoice",
        "key": "stripeCharge",
        "type": "rel",
        "format": "stripe-charge",
        "title": "stripeCharge",
        "additionalProperties": [],
        "description": "",
        "className": "StripeInvoice",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-invoice",
        "key": "stripeCustomer",
        "type": "rel",
        "format": "stripe-customer",
        "title": "stripeCustomer",
        "additionalProperties": [],
        "description": "",
        "className": "StripeInvoice",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-invoice",
        "key": "stripeSubscription",
        "type": "rel",
        "format": "stripe-subscription",
        "title": "stripeSubscription",
        "additionalProperties": [],
        "description": "",
        "className": "StripeInvoice",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "calculatetCrmEmail",
        "type": "string",
        "format": "",
        "title": "CRM email",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "calculatetCrmOrderId",
        "type": "string",
        "format": "",
        "title": "CRM order",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "crmEmail",
        "type": "string",
        "format": "",
        "title": "crmEmail",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "crmOrderId",
        "type": "string",
        "format": "",
        "title": "crmOrderId",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "metaData",
        "type": "array",
        "format": "string",
        "title": "metaData",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "paymentMethod",
        "type": "string",
        "format": "",
        "title": "paymentMethod",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "receiptEmail",
        "type": "string",
        "format": "",
        "title": "receiptEmail",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "statementDescriptor",
        "type": "string",
        "format": "",
        "title": "statementDescriptor",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "statementDescriptorSuffix",
        "type": "string",
        "format": "",
        "title": "statementDescriptorSuffix",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "stripeCustomer",
        "type": "rel",
        "format": "stripe-customer",
        "title": "stripeCustomer",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-payment-intent",
        "key": "stripeInvoice",
        "type": "rel",
        "format": "stripe-invoice",
        "title": "stripeInvoice",
        "additionalProperties": [],
        "description": "",
        "className": "StripePaymentIntent",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-refund",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "stripe-refund",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "stripe-refund",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-refund",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-refund",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-refund",
        "key": "metaData",
        "type": "array",
        "format": "string",
        "title": "metaData",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "stripe-refund",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "stripe-refund",
        "key": "reason",
        "type": "string",
        "format": "",
        "title": "reason",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-refund",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-refund",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-refund",
        "key": "stripeBalanceTransaction",
        "type": "rel",
        "format": "stripe-balance-transaction",
        "title": "stripeBalanceTransaction",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-refund",
        "key": "stripeCharge",
        "type": "rel",
        "format": "stripe-charge",
        "title": "stripeCharge",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-refund",
        "key": "stripePaymentIntent",
        "type": "rel",
        "format": "stripe-payment-intent",
        "title": "stripePaymentIntent",
        "additionalProperties": [],
        "description": "",
        "className": "StripeRefund",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-subscription",
        "key": "crmEmail",
        "type": "string",
        "format": "",
        "title": "crmEmail",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-subscription",
        "key": "crmOrderId",
        "type": "string",
        "format": "",
        "title": "crmOrderId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-subscription",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-subscription",
        "key": "finishDate",
        "type": "string",
        "format": "date",
        "title": "finishDate",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "stripe-subscription",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-subscription",
        "key": "metaData",
        "type": "array",
        "format": "string",
        "title": "metaData",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "stripe-subscription",
        "key": "plan",
        "type": "array",
        "format": "string",
        "title": "plan",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "stripe-subscription",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-subscription",
        "key": "startDate",
        "type": "string",
        "format": "date",
        "title": "startDate",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "stripe-subscription",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-subscription",
        "key": "stripeCustomer",
        "type": "rel",
        "format": "stripe-customer",
        "title": "stripeCustomer",
        "additionalProperties": [],
        "description": "",
        "className": "StripeSubscription",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "stripe-webhook",
        "key": "apiVersion",
        "type": "string",
        "format": "",
        "title": "apiVersion",
        "additionalProperties": [],
        "description": "",
        "className": "StripeWebhook",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-webhook",
        "key": "description",
        "type": "string",
        "format": "",
        "title": "description",
        "additionalProperties": [],
        "description": "",
        "className": "StripeWebhook",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-webhook",
        "key": "enabledEvents",
        "type": "string",
        "format": "text",
        "title": "enabledEvents",
        "additionalProperties": [],
        "description": "",
        "className": "StripeWebhook",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "stripe-webhook",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "StripeWebhook",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "stripe-webhook",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "StripeWebhook",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-webhook",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "StripeWebhook",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "stripe-webhook",
        "key": "url",
        "type": "string",
        "format": "",
        "title": "url",
        "additionalProperties": [],
        "description": "",
        "className": "StripeWebhook",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "arguments",
        "type": "string",
        "format": "",
        "title": "arguments",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "command",
        "type": "string",
        "format": "",
        "title": "command",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "executeFinish",
        "type": "string",
        "format": "date-time",
        "title": "executeFinish",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "executeStart",
        "type": "string",
        "format": "date-time",
        "title": "executeStart",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "system-run",
        "key": "response",
        "type": "string",
        "format": "text",
        "title": "response",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "system-run",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "authSourceId",
        "type": "integer",
        "format": "",
        "title": "authSourceId",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "webhook",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "webhook",
        "key": "data",
        "type": "array",
        "format": "string",
        "title": "data",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "webhook",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "webhook",
        "key": "processed",
        "type": "boolean",
        "format": "",
        "title": "processed",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "webhook",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    }
]