import { IFrontOrderLogFieldsNae } from './useFrontOrderLogHookNae';
import { IRecurringFieldsNae } from './useRecurringHookNae';
import { IStripeChargeFieldsNae } from './useStripeChargeHookNae';
import { IStripeDisputeFieldsNae } from './useStripeDisputeHookNae';
import { IStripeEarlyFraudWarningFieldsNae } from './useStripeEarlyFraudWarningHookNae';
import { IStripeRefundFieldsNae } from './useStripeRefundHookNae';
import { IStripeWebhookFieldsNae } from './useStripeWebhookHookNae';
import { IWebhookFieldsNae } from './useWebhookHookNae';
import { SelectorFrontOrderLogNae,SelectorRecurringNae,SelectorStripeChargeNae,SelectorStripeDisputeNae,SelectorStripeEarlyFraudWarningNae,SelectorStripeRefundNae,SelectorStripeWebhookNae,SelectorWebhookNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.FrontOrderLog.className) {
            return {
                dataToCheck: SelectorFrontOrderLogNae(store.getState()),
                fields: IFrontOrderLogFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Recurring.className) {
            return {
                dataToCheck: SelectorRecurringNae(store.getState()),
                fields: IRecurringFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.StripeCharge.className) {
            return {
                dataToCheck: SelectorStripeChargeNae(store.getState()),
                fields: IStripeChargeFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.StripeDispute.className) {
            return {
                dataToCheck: SelectorStripeDisputeNae(store.getState()),
                fields: IStripeDisputeFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.StripeEarlyFraudWarning.className) {
            return {
                dataToCheck: SelectorStripeEarlyFraudWarningNae(store.getState()),
                fields: IStripeEarlyFraudWarningFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.StripeRefund.className) {
            return {
                dataToCheck: SelectorStripeRefundNae(store.getState()),
                fields: IStripeRefundFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.StripeWebhook.className) {
            return {
                dataToCheck: SelectorStripeWebhookNae(store.getState()),
                fields: IStripeWebhookFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Webhook.className) {
            return {
                dataToCheck: SelectorWebhookNae(store.getState()),
                fields: IWebhookFieldsNae,    
            }
        }
        return undefined;
}