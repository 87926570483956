import { useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle'
import React, { useEffect } from 'react'
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths'
import { TabContainer } from '@newageerp/v3.bundles.layout-bundle';
import Disputes from './Components/Disputes/Disputes';
import EarlyFraudWarning from './Components/Disputes/EarlyFraudWarning';


export default function DashboardEarlyFraudWarning() {
  const [doReq, doReqParams] = useURequestRaw<any>(NaePathsMap.post.StripeEarlyFraudWarningReports);


  const loadData = () => {
    doReq({});
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className='space-y-2'>
      <TabContainer
        items={[
          {
            content: <Disputes />,
            tab: {
              children: 'Disputes'
            }
          },
          {
            content: <EarlyFraudWarning />,
            tab: {
              children: 'Early fraud warnings'
            }
          }
        ]}
      />
    </div>
  )
}
