import { useURequestRaw } from '@newageerp/v3.bundles.hooks-bundle'
import React, { Fragment, useEffect } from 'react'

import { XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import { LogoLoader } from '@newageerp/v3.bundles.layout-bundle';
import { NaePathsMap } from '../../../../_generated/_custom/config/NaePaths';


export default function EarlyFraudWarning() {
  const [doReq, doReqParams] = useURequestRaw<any>(NaePathsMap.post.StripeEarlyFraudWarningReports);

  const loadData = () => {
    doReq({});
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Fragment>
      {doReqParams.loading && <LogoLoader />}
      {!!doReqParams.data && !!doReqParams.data.data &&
        <ResponsiveContainer width="100%" height={450}>
          <BarChart width={600} height={450} data={doReqParams.data.data}>
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar type="monotone" dataKey="refunds" fill="#14813F" stackId="1" />
            <Bar type="monotone" dataKey="disputes" fill="#DA2727" stackId="1" />
            {/* <Area type="monotone" dataKey="all" stroke="#0A68A1"  fill="#0A68A1" stackId="1"/> */}
          </BarChart>
        </ResponsiveContainer>
      }
    </Fragment>
  )
}
